var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":_vm.$t('tax.tax-secondary-type')}},[_c('v-data-table',{attrs:{"headers":_vm.showHeaders,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"show-first-last-page":true,"show-current-page":true,"first-icon":"mdi-chevron-double-left","last-icon":"mdi-chevron-double-right","prev-icon":"mdi-chevron-left","next-icon":"mdi-chevron-right","items-per-page-options":[20, 50, 100]},on:{"update:options":updateOptions}})]}},{key:"item.tax_primary_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_primary_type_name(item))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_type_name(item))+" ")]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("Translate")(_vm._f("getText")(item.unit,_vm.unit_items)))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }