<template>
  <v-container fluid>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('tax.tax-secondary-type')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="showHeaders"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100]"
          />
        </template>
        <template v-slot:item.tax_primary_type="{ item }">
          {{ get_primary_type_name(item) }}
        </template>
        <template v-slot:item.name="{ item }">
          {{ get_type_name(item) }}
        </template>
        <template v-slot:item.unit="{ item }">
          {{ item.unit | getText(unit_items) | Translate }}
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
  <script>
import BaseTable from "./BaseTable.vue";
import { unit_items } from "@/definition.js";
export default {
  data: (vm) => ({
    url: "/commodity/tax-secondary-type/",
    headersMap: {
      id:{
        sortable: true,
        text: vm.$i18n.t("ID"),
        value: "id",
      },
      tax_primary_type:{
        sortable: true,
        text: vm.$i18n.t("commodity.tax-primary-type"),
        value: "tax_primary_type",
      },
      name_tc:{
        sortable: true,
        text: vm.$i18n.t("name"),
        value: "name_tc",
      },
      name_sc:{
        sortable: true,
        text: vm.$i18n.t("name"),
        value: "name_sc",
      },
      unit:{
        sortable: true,
        text: vm.$i18n.t("commodity.unit"),
        value: "unit",
      },
      unit_fee:{
        sortable: true,
        text: vm.$i18n.t("commodity.unit-price"),
        value: "unit_fee",
      },
    },
    tcHeaders: ['id','tax_primary_type', 'name_tc', 'unit',  'unit_fee'],
    scHeaders: ['id','tax_primary_type', 'name_sc', 'unit',  'unit_fee'],
    unit_items: unit_items,
  }),
  mixins: [BaseTable],
  methods: {
    get_primary_type_name(item) {
      return this.getHanText(item.tax_primary_type, "name");
    },
    get_type_name(item) {
      return this.getHanText(item, "name");
    },
  },
};
</script>